<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/validations`"
                title="Validations"
                :columns="columns"
                routerpath="/setup/addvalidations"
                :formOptions="formOptions"/>
                  <b-button variant="primary" label="hello" class="mr-2">Run</b-button>
                  <label style="font-size: 1.3rem;">Suspense Account Check</label><br><br>
                  <b-button variant="primary" label="hello" class="mr-2">Run</b-button>
                  <label style="font-size: 1.3rem;">Single Item Stock</label><br><br>
                  <b-button variant="primary" label="hello" class="mr-2">Run</b-button>
                  <label style="font-size: 1.3em;">Single Ledger Stock</label><br><br>
                  <b-button variant="primary" label="hello" class="mr-2">Run</b-button>
                  <label style="font-size: 1.3rem;">Account Double Entry Check</label><br><br>
                  <b-button variant="primary" label="hello" class="mr-2">Run</b-button>
                  <label style="font-size: 1.3rem;">Check For Deleted Ledger</label><br><br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy
} from 'bootstrap-vue'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value
} from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from '@/components/axios'
import moment from 'moment'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID'
          }
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search name'
          }
        },
        {
          label: 'Address',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Address'
          }
        },
        {
          label: 'City',
          field: 'city',
          filterOptions: {
            enabled: true,
            placeholder: 'Search City'
          }
        },
        {
          label: 'Pincode',
          field: 'pincode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Pincode'
          }
        },
        {
          label: 'State',
          field: 'state',
          filterOptions: {
            enabled: true,
            placeholder: 'Search State'
          }
        },
        {
          label: 'GST no',
          field: 'gstno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search GST no'
          }
        },
        {
          label: 'CST no',
          field: 'cstno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search CST no'
          }
        },
        {
          label: 'Pan no',
          field: 'panno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Pan no'
          }
        },
        {
          label: 'Service tax number',
          field: 'servicetaxnumber',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Service tax number'
          }
        },
        {
          label: 'Phone',
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Phone no'
          }
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email'
          }
        },
        {
          label: 'Website',
          field: 'website',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Website'
          }
        },
        {
          label: 'Company Logo',
          field: 'logo',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Company Logo'
          }
        },

        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Company' : 'Add Company'}`,
        submitRouterPath: '/setup/company',
        gridForm: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/company`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Company Name',
            name: 'name',
            value: '',
            type: 'text',
            placeholder: 'Enter Name',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            placeholder: 'Enter city',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'State',
            name: 'state',
            value: '',
            type: 'text',
            placeholder: 'Enter state',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Country',
            name: 'country',
            value: '',
            type: 'text',
            placeholder: 'Enter Country',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Pin Code',
            name: 'pincode',
            value: '',
            type: 'text',
            placeholder: 'Enter Pincode',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Financial Year',
            name: 'financialyear',
            value: '',
            type: 'text',
            placeholder: 'Enter Financial Year',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'GST No',
            name: 'gstno',
            value: '',
            type: 'number',
            placeholder: 'Enter GST No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'CST No',
            name: 'cstno',
            value: '',
            type: 'number',
            placeholder: 'Enter CST No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Pan No',
            name: 'panno',
            value: '',
            type: 'text',
            placeholder: 'Enter Pan No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Service Tax Number',
            name: 'servicetaxnumber',
            value: '',
            type: 'text',
            placeholder: 'Enter Service Tax Number',
            class: 'col-md-6'
            // required: true,
          },
          {
            label: 'Phone No',
            name: 'phoneno',
            value: '',
            type: 'number',
            placeholder: 'Enter Phone No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'E-mail',
            name: 'email',
            value: '',
            type: 'email',
            placeholder: 'Enter Email',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Website',
            name: 'website',
            value: '',
            type: 'number',
            placeholder: 'Enter Website',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Logo',
            name: 'logo',
            value: '',
            type: 'fileinput',
            placeholder: 'select Logo',
            class: 'col-md-6'
            // required: true,
          }
        ]
      }
    }
  },
  beforeMount () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
